.tableContainer {
  font-size: 1.25rem;
}
@media (max-width: 768px) {
  .orderBook {
    padding: 0 10px;
  }

  .tableContainer {
    font-size: 0.9rem;
    flex-direction: column;
  }

  .tableWrapper {
    order: 1;
  }

  .tableWrapper:first-child {
    order: 0;
  }
}

.row:hover {
  background-color: cadetblue;
  color: white;
}
